<template lang="pug">
v-tabs(color="newPrimary" v-model="activeTab" background-color="transparent" height="40px").app-tabs
  v-tab(
    v-for="tab in tabs"
    :key="tab.name"
    @click="switchTab(tab.value)"
  )
    div
      div {{ tab.label }}
        btn(small).ml-2.tab-count {{ tab.count }}
</template>

<script>
import { TASK_APPLICATIONS_TAB } from '../core/taskApplications-const'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  props: {
    counts: Object
  },

  mixins: [featureFlagsMixin],

  data: () => ({
    activeTab: 0,
  }),

  computed: {
    tabs() {
      return [
        {
          name: TASK_APPLICATIONS_TAB.TASKS,
          label: 'Tasks',
          count: this.counts[TASK_APPLICATIONS_TAB.TASKS],
          value: TASK_APPLICATIONS_TAB.TASKS,
        },
        {
          name: TASK_APPLICATIONS_TAB.WAIT,
          label: 'Wait',
          count: this.counts[TASK_APPLICATIONS_TAB.WAIT],
          value: TASK_APPLICATIONS_TAB.WAIT
        },
        {
          name: TASK_APPLICATIONS_TAB.FUTURE,
          label: 'Future',
          count: this.counts[TASK_APPLICATIONS_TAB.FUTURE],
          value: TASK_APPLICATIONS_TAB.FUTURE
        },
        {
          name: TASK_APPLICATIONS_TAB.CANCELED,
          label: 'Canceled',
          count: this.counts[TASK_APPLICATIONS_TAB.CANCELED],
          value: TASK_APPLICATIONS_TAB.CANCELED
        },
        {
          name: TASK_APPLICATIONS_TAB.PAID,
          label: 'Paid',
          count: this.counts[TASK_APPLICATIONS_TAB.PAID],
          value: TASK_APPLICATIONS_TAB.PAID
        },
      ]
    }
  },

  created() {
    this.setTabByQuery()
  },

  methods: {
    async switchTab(value) {
      if (this.$route.query.tab === value) return

      await this.$router.replace({
        query: {
          ...this.$route.query,
          tab: value,
          page: 1
        }
      })
      this.$emit('change')
    },

    setTabByQuery() {
      let name = this.$route.query.tab
      let tabIndex = this.tabs.findIndex(tab => tab.value === name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  },

  components: {
    btn: () => import('@/components/global/BtnCustom.vue')
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/tabsNew";
</style>
